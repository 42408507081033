import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from 'src/app/services/cognito.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { JobService } from 'src/app/services/job.service';
import { ApiService } from 'src/app/services/api.service';
import { ApiConfig } from 'src/app/utils/config/apiConfig';
import { calculateTime } from 'src/app/utils/timeAgo';
import { catchError, of } from 'rxjs';
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { ChatService } from 'src/app/services/chat.service';
interface INotification {
  _id: string;
  title: string;
  description: string;
  is_read: boolean;
}
@Component({
  selector: 'app-dashboard-layout',
  styleUrls: ['./dashboard-layout.component.scss'],
  templateUrl: './dashboard-layout.component.html',
})
export class DashboardLayoutComponent {
  isModalOpen: boolean = false;
  isFeedbackModalOpen: boolean = false;
  contactForm: FormGroup;
  feedbackForm: FormGroup;
  issueType: string = '';
  isSelected!: boolean;
  isNotificationPanelVisible: boolean = false;
  unreadCount: number = 0;
  notifications: INotification[] = [];
  showViewAll: boolean = false;
  notificationUrl: string;
  hasMoreNotifications: boolean = false;
  loadingMore: boolean = false;
  offset: number = 1;
  isSmallScreen: boolean = false;
  isCollapsed: BooleanInput;
  showChatBox: boolean = false;
  conversationId: string = 'admin-client';
  sender: string = 'Client';
  senderID: string = '';
  recipientID: string = 'BD Admin';

  constructor(
    private cognitoService: CognitoService,
    private router: Router,
    private notification: NzNotificationService,
    private fb: FormBuilder,
    private jobService: JobService,
    private apiService: ApiService,
    private chatService: ChatService,
  ) {
    this.contactForm = this.fb.group({
      type: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.feedbackForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      feedback_text: ['', Validators.required],
    });

    this.notificationUrl = ApiConfig.urlConstant.notification;
  }

  ngOnInit(): void {
    this.checkScreenSize();
    this.getAllNotification();
    this.getUnreadNotificationCount();
  }

  // Method to handle user sign out
  signOut() {
    this.cognitoService.handleSignOut().then((res: any) => {
      this.router.navigate(['/login']);
      localStorage.clear();
    });
  }

  makeQuery() {
    const payload = this.contactForm.value;
    this.jobService.postQuery({ ...payload }).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.isModalOpen = false;
          this.notification.success('Success', 'Your query raised successfully!');
          this.contactForm.reset();
          this.chatService.sendMessage(
            data.ticket_id,
            payload.description,
            this.sender,
            data.client_id,
            this.recipientID,
          );
          this.openChat(data.ticket_id, data.client_id);
        }
      },
    });
  }

  handleModalClose() {
    this.isModalOpen = false;
  }

  handleModalOpen() {
    this.isModalOpen = true;
  }

  postFeedback() {
    const payload = this.feedbackForm.value;
    this.jobService.postFeedback({ ...payload }).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.handleFeedbackModalClose();
          this.notification.success('Success', 'Your Feedback is sent successfully!');
          this.feedbackForm.reset();
        }
      },
    });
  }

  handleFeedbackModalClose() {
    this.isFeedbackModalOpen = false;
    this.isSelected = false;
  }

  openFeedbackModal() {
    this.isFeedbackModalOpen = true;
  }

  getAllNotification() {
    this.apiService
      .getRequest(this.notificationUrl, {
        params: {
          limit: 5,
          sortOrder: 'DESC',
        },
      })
      .subscribe({
        next: (result) => {
          const { data, success } = result;
          if (data) {
            this.notifications = data?.data;
            if (data?.total > 5) this.showViewAll = true;
            this.hasMoreNotifications = data?.hasNextPage;
          }
        },
      });
  }

  onLoadMoreNotifications() {
    this.loadingMore = true;
    this.offset++;
    this.apiService
      .getRequest(`${this.notificationUrl}`, {
        params: {
          offset: this.offset,
          sortOrder: 'DESC',
          limit: 5,
        },
      })
      .pipe(catchError(() => of({ results: [] })))
      .subscribe((res: any) => {
        const { data, success } = res;
        const notificationsSize = this.notifications.length;
        if (this.notifications[notificationsSize - 1]?._id === data.data[0]?._id) {
          // console.log('ok');

          this.notifications.pop();
        }
        this.notifications = this.notifications.concat(data.data);
        this.hasMoreNotifications = res?.data?.hasNextPage;
        this.loadingMore = false;
      });
  }

  calculateTime(date: string) {
    return calculateTime(date);
  }

  markAllAsRead() {
    this.apiService.patchRequest(`${this.notificationUrl}/mark-all-as-read`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.notifications.forEach((item) => {
            item.is_read = true;
          });
          this.getUnreadNotificationCount();
        }
      },
    });
  }

  viewAllNotifications() {}

  markAsRead(id: string) {
    this.apiService.patchRequest(`${this.notificationUrl}/${id}/mark-as-read`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          const indexOfReaded = this.notifications.findIndex((item) => item?._id === id);
          this.notifications[indexOfReaded].is_read = true;
          this.getUnreadNotificationCount();
        }
      },
    });
  }

  getUnreadNotificationCount() {
    this.apiService.getRequest(`${this.notificationUrl}/unread-count`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data?.unreadCount >= 0) {
          this.unreadCount = data?.unreadCount;
          // console.log(data);
        }
      },
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth < 768;
  }

  handleSidebar() {
    if (this.isSmallScreen) this.isCollapsed = true;
  }

  openChat(conversationId: string, senderID: string) {
    this.conversationId = conversationId;
    this.senderID = senderID;
    this.showChatBox = true;
  }

  closeChat() {
    this.conversationId = '';
    this.senderID = '';
    this.showChatBox = false;
  }
}
