import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { config, CognitoIdentityCredentials, S3 } from 'aws-sdk';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { environment } from 'src/environments/environment';

interface IFileStructure {
  name: string;
  short_filename: string;
  status: string;
  uid: string;
  url: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  s3: any;
  @Input() form!: FormGroup;
  @Input() fileFormControlName!: string;
  @Input() fileList: any = [];
  @Input() singleFile: boolean = false;
  @Input() uploadType: string = 'default';
  loading: boolean = false;
  fileArray: any;

  constructor(private messageService: NzMessageService) {}

  ngOnInit() {
    // Configure AWS S3 client
    config.update({
      region: environment.awsConfig.region,
      credentials: new CognitoIdentityCredentials({
        IdentityPoolId: environment.awsConfig.identityPoolId,
      }),
    });
    this.s3 = new S3({
      apiVersion: '2006-03-01',
      params: { Bucket: environment.awsConfig.userBucket },
    });
    if (this.fileList.length) this.fileArray = this.fileList;
  }

  customUpload = (item: any) => {
    const file = item.file;
    const { file_name, short_filename } = this.getFileNames(file);
    const params = {
      Bucket: environment.awsConfig.userBucket,
      Key: file_name,
      Body: file,
      ContentType: file.type,
      ContentDisposition: `attachment; filename=${short_filename}`,
      ACL: 'public-read',
    };
    if (this.uploadType === 'chat') {
      this.loading = true;
    }
    const request = this.s3.upload(params);

    request.on('httpUploadProgress', function (progress: any) {
      // console.log(progress.loaded + " of " + progress.total + " bytes");
      const event = { percent: 0 };
      event.percent = (progress.loaded / progress.total) * 100;
      item.onProgress(event, item.file);
    });

    return request
      .promise()
      .then(() => {
        this.loading = false;
        this.messageService.success('File uploaded successfully!');
        const s3_url = 'https://s3-ap-south-1.amazonaws.com/project-content.builtdesign.in/' + file_name;
        item.onSuccess(
          { uid: item.file.uid, name: item.file.name, short_filename: short_filename, url: s3_url, status: 'done' },
          request,
        );
        this.fileArray = this.form?.controls[this.fileFormControlName]?.value || [];
        this.fileArray.push({
          uid: item.file.uid,
          name: item.file.name,
          short_filename: short_filename,
          url: s3_url,
          status: 'done',
        });
        this.form?.controls[this.fileFormControlName]?.setValue(this.fileArray);
      })
      .catch((err: any) => this.handleError(err));
  };

  getFileNames(file: any) {
    const folderDates = `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}/`;
    let file_name = folderDates + file.name;
    file_name = file_name.split(' ').join('_').toLowerCase();
    const ext = file_name.split('.').reverse()[0];
    const short_filename = (folderDates + ' ' + 'dev-test').split(' ').join('_').toLowerCase() + '.' + ext;
    return { file_name, short_filename };
  }

  private handleError(error: any) {
    this.messageService.error('File upload failed:', error);
    console.error(error.message || error);
  }

  customDownload = (file: NzUploadFile) => {
    window.open(file.response.url, 'download_window');
  };

  customRemove = (file: NzUploadFile) => {
    const index = this.fileArray.findIndex((item: IFileStructure) => item.uid === file.uid);
    if (index !== -1) {
      this.fileArray.splice(index, 1);
      this.form?.controls[this.fileFormControlName]?.setValue(this.fileArray);
      return true;
    }
    return false;
  };

  previewImage: string | undefined = '';
  previewVisible = false;

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file['preview']) {
      file['preview'] = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file['preview'];
    this.previewVisible = true;
  };
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
