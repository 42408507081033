import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-with-us',
  templateUrl: './chat-with-us.component.html',
  styleUrls: ['./chat-with-us.component.scss'],
  // encapsulation: ViewEncapsulation.Emulated,
})
export class ChatWithUsComponent implements OnInit {
  @Input() conversationId: string = ''; // Conversation ID/Ticket ID passed from parent
  @Input() sender: string = ''; // Sender's name (e.g., Admin or Client)
  @Input() senderID: string = ''; // Sender's ID
  @Input() recipientID: string = '';
  @Input() chat: boolean = false;
  @Input() jobId: string = '';

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport | undefined;

  loading: boolean = false;
  inputValue!: string;
  submitting!: boolean;
  fileForm: FormGroup;
  messages: any[] = [];
  newMessage: string = '';

  constructor(private fb: FormBuilder, private chatService: ChatService) {
    this.fileForm = this.fb.group({
      files: [[]],
    });
  }

  ngOnInit() {
    if (this.conversationId) {
      this.chatService.chatWithUsGetMessages(this.jobId, this.conversationId).subscribe((messages) => {
        this.messages = messages;
        setTimeout(() => {
          this.viewportScrollToBottom();
        }, 100);
      });
    }
  }

  sendMessage() {
    if (this.newMessage.trim()) {
      this.chatService
        .chatWithUsSendMessage(
          this.jobId,
          this.conversationId,
          this.newMessage,
          this.sender,
          this.senderID,
          this.recipientID,
        )
        .then(() => {
          this.newMessage = ''; // Clear input field after sending
        });
    }
  }
  // messages: any = [
  //   {
  //     type: 'text', // Text message type
  //     message: 'Hello! How can I assist you today?',
  //     reply: false, // Message sent by support, not the user
  //     user: {
  //       name: 'Admin',
  //     },
  //     date: new Date('2023-09-01T10:30:00'), // Custom date
  //   },
  //   {
  //     type: 'text',
  //     message: 'I am facing issues with my recent order.',
  //     reply: true, // This message is sent by the user
  //     user: {
  //       name: 'Bd-112293',
  //     },
  //     date: new Date('2023-09-01T10:31:00'),
  //   },
  //   {
  //     type: 'file',
  //     message: "Here's the invoice for your order: invoice_123.pdf",
  //     reply: false,
  //     user: {
  //       name: 'BD-10092',
  //     },
  //     date: new Date('2023-09-01T10:32:00'),
  //     files: [
  //       {
  //         url: 'https://example.com/invoice_123.pdf',
  //         icon: 'file-text-outline',
  //         name: 'invoice_123.pdf',
  //       },
  //     ],
  //   },
  //   {
  //     type: 'quote',
  //     message: 'Can you check if the delivery address is correct?',
  //     reply: true,
  //     user: {
  //       name: 'BD-19292',
  //     },
  //     date: new Date('2023-09-01T10:33:00'),
  //     files: null,
  //     quote: {
  //       message: 'Order #123456 has been shipped.',
  //       sender: 'Support',
  //       date: '2023-08-31T10:00:00',
  //     },
  //   },
  //   {
  //     type: 'map',
  //     message: 'Here is the location of your delivery.',
  //     reply: false,
  //     user: {
  //       name: 'BD-11129',
  //     },
  //     date: new Date('2023-09-01T10:34:00'),
  //     latitude: 37.7749, // Example coordinates (San Francisco)
  //     longitude: -122.4194,
  //   },
  //   {
  //     type: 'text',
  //     message: 'Thanks for the update! The address is correct.',
  //     reply: true,
  //     user: {
  //       name: 'BD-10010',
  //     },
  //     date: new Date('2023-09-01T10:35:00'),
  //   },
  // ];
  // messages: any = [
  //   {
  //     type: 'text', // Text message type
  //     message: 'Hello! How can I assist you today?',
  //     reply: false, // Message sent by support, not the user
  //     user: {
  //       name: 'Support',
  //     },
  //     date: new Date('2023-09-01T10:30:00'), // Custom date
  //   },
  //   {
  //     type: 'text',
  //     message: 'I am facing issues with my recent order.',
  //     reply: true, // This message is sent by the user
  //     user: {
  //       name: 'User',
  //     },
  //     date: new Date('2023-09-01T10:31:00'),
  //   },
  //   {
  //     type: 'file',
  //     message: "Here's the invoice for your order: invoice_123.pdf",
  //     reply: false,
  //     user: {
  //       name: 'Support',
  //     },
  //     date: new Date('2023-09-01T10:32:00'),
  //     files: [
  //       {
  //         url: 'https://example.com/invoice_123.pdf',
  //         icon: 'file-text-outline',
  //         name: 'invoice_123.pdf',
  //       },
  //     ],
  //   },
  //   {
  //     type: 'quote',
  //     message: 'Can you check if the delivery address is correct?',
  //     reply: true,
  //     user: {
  //       name: 'User',
  //     },
  //     date: new Date('2023-09-01T10:33:00'),
  //     files: null,
  //     quote: {
  //       message: 'Order #123456 has been shipped.',
  //       sender: 'Support',
  //       date: '2023-08-31T10:00:00',
  //     },
  //   },
  //   {
  //     type: 'map',
  //     message: 'Here is the location of your delivery.',
  //     reply: false,
  //     user: {
  //       name: 'Support',
  //     },
  //     date: new Date('2023-09-01T10:34:00'),
  //     latitude: 37.7749, // Example coordinates (San Francisco)
  //     longitude: -122.4194,
  //   },
  //   {
  //     type: 'text',
  //     message: 'Thanks for the update! The address is correct.',
  //     reply: true,
  //     user: {
  //       name: 'User',
  //     },
  //     date: new Date('2023-09-01T10:35:00'),
  //   },
  // ];

  // data: any = [
  //   {
  //     type: 'text', // Text message type
  //     message: 'Hello! How can I assist you today?',
  //     reply: false, // Message sent by support, not the
  //     user_type: 'me',
  //     user: {
  //       name: 'Support',
  //     },
  //     date: new Date('2023-09-01T10:30:00'), // Custom date
  //   },
  //   {
  //     type: 'text',
  //     message: 'I am facing issues with my recent order.',
  //     reply: true, // This message is sent by the user
  //     user_type: 'you',
  //     user: {
  //       name: 'User',
  //     },
  //     date: new Date('2023-09-01T10:31:00'),
  //   },
  //   {
  //     type: 'file',
  //     message: "Here's the invoice for your order: invoice_123.pdf",
  //     reply: false,
  //     user_type: 'me',
  //     user: {
  //       name: 'Support',
  //     },
  //   },
  //   {
  //     type: 'file',
  //     message: "Here's the invoice for your order: invoice_123.pdf",
  //     reply: false,
  //     user_type: 'you',
  //     user: {
  //       name: 'Support',
  //     },
  //   },
  // ];
  // sendMessage(event: any) {
  //   const newMessage = {
  //     message: event.message,
  //     sender: 'User',
  //     date: new Date(),
  //     type: 'text',
  //     reply: true,
  //   };
  //   this.messages.push(newMessage);
  // }

  openChat() {
    this.chat = true;
  }

  handleSubmit() {}

  backBtn() {
    this.chat = false;
  }

  viewportScrollToBottom(): void {
    this.viewport?.scrollTo({
      bottom: this.messages.length,
      behavior: 'smooth',
    });
  }
}
