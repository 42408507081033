<nz-upload
  *ngIf="singleFile; else multipleUpload"
  nzType="drag"
  [nzCustomRequest]="customUpload"
  [nzName]="'file'"
  [nzListType]="'picture-card'"
  [(nzFileList)]="fileList"
  [nzDownload]="customDownload"
  [nzRemove]="customRemove"
  [nzPreview]="handlePreview"
  class="file-upload"
  [nzShowButton]="fileList.length < 2"
  [nzMultiple]="true"
>
  <div>
    <span nz-icon nzType="plus"></span>
    <div style="margin-top: 8px">Upload</div>
  </div>
</nz-upload>

<ng-template #multipleUpload>
  <nz-upload
    nzType="drag"
    [nzCustomRequest]="customUpload"
    [nzName]="'file'"
    [nzListType]="'picture-card'"
    [(nzFileList)]="fileList"
    [nzDownload]="customDownload"
    [nzRemove]="customRemove"
    [nzPreview]="handlePreview"
    class="file-upload"
    [nzShowButton]="fileList?.length < 2"
    [nzMultiple]="true"
  >
    <p class="ant-upload-drag-icon">
      <span nz-icon nzType="inbox"></span>
    </p>
    <p class="ant-upload-text">Click to upload or drag and drop</p>
    <p class="ant-upload-hint">PDF, JPEG, PNG, (max size: 10MB)</p>
  </nz-upload>
</ng-template>
<nz-modal
  [nzVisible]="previewVisible"
  [nzContent]="modalContent"
  [nzFooter]="null"
  (nzOnCancel)="previewVisible = false"
>
  <ng-template #modalContent>
    <img [src]="previewImage" style="width: 100%" />
  </ng-template>
</nz-modal>
