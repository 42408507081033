export class ApiConfig {
  static readonly urlConstant = {
    profile: '/profile',
    metaData: '/utils/metadata',
    user: '/user',
    client: '/client',
    userProfile: '/user/profiles',
    jobs: '/jobs',
    pastCompetition: '/jobs/past-competitions',
    bid: '/bid',
    support: '/support',
    supportList: '/support/user',
    notification: '/notification',
    forum: '/forum',
    unsubscribe: '/campaign/unsubscribe',
  };
}
