// import { Injectable } from '@angular/core';
// import { AngularFireDatabase } from '@angular/fire/compat/database';

// @Injectable({
//   providedIn: 'root',
// })
// export class ChatService {
//   constructor(private db: AngularFireDatabase) {}

//   getConversations(userId: string, role: string) {
//     if (role === 'admin') {
//       // Admin sees all conversations
//       return this.db.list('conversations').valueChanges();
//     } else {
//       // Client sees only their conversation
//       return this.db
//         .list('conversations', (ref) =>
//           ref.orderByChild('clientId').equalTo(userId)
//         )
//         .valueChanges();
//     }
//   }

//   sendMessage(conversationId: string, sender: string, message: string) {
//     this.db.list(`conversations/${conversationId}/messages`).push({
//       sender,
//       message,
//       timestamp: Date.now(),
//     });
//   }

//   startConversation(clientId: string, clientName: string) {
//     const conversationId = this.db.createPushId();
//     this.db.object(`conversations/${conversationId}`).set({
//       clientId,
//       clientName,
//       messages: [],
//     });
//     return conversationId;
//   }
// }

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, Observable } from 'rxjs';
import { increment } from 'firebase/database';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  supportChatInitialPath: string = 'supports';
  chatWithUsInitialPath: string = 'job-chats';

  constructor(private firestore: AngularFirestore, private db: AngularFireDatabase) {}

  // Get messages for a specific conversation
  getMessages(conversationId: string): Observable<any[]> {
    // return this.firestore
    //   .collection('conversations')
    //   .doc(conversationId)
    //   .collection('messages', (ref) => ref.orderBy('timestamp'))
    //   .valueChanges();
    return this.db.list(`${this.supportChatInitialPath}/conversations/${conversationId}/messages`).valueChanges();
  }

  // Send a message to a conversation
  sendMessage(conversationId: string, message: string, sender: string, senderID: string, recipientId: string) {
    // const messageData = {
    //   message,
    //   sender,
    //   timestamp: new Date(),
    //   senderID,
    // };

    // return this.firestore.collection('conversations').doc(conversationId).collection('messages').add(messageData);

    return this.db
      .list(`${this.supportChatInitialPath}/conversations/${conversationId}/messages`)
      .push({
        sender,
        message,
        timestamp: Date.now(),
        senderID,
        recipientId,
        isRead: false,
      })
      .then(() => {
        // Increment unread count for recipient
        this.db
          .object(`${this.supportChatInitialPath}/conversations/${conversationId}/users/${recipientId}`)
          .update({ unreadCount: increment(1) });
      });
  }
  markAsRead(conversationId: string, userId: string): Promise<void> {
    return this.db.object(`${this.supportChatInitialPath}/conversations/${conversationId}/users/${userId}`).update({
      unreadCount: 0,
      lastRead: Date.now(),
    });
  }

  getUnReadMessages(conversationId: string, userId: string): Observable<any[]> {
    // return this.db
    //   .list(`/conversations/${conversationId}/users`)
    //   .snapshotChanges()
    //   .pipe(
    //     map((users) =>
    //       users.map((user) => ({
    //         userId: user.key,
    //         ...(user.payload.val() as any),
    //       })),
    //     ),
    //   );
    // return this.db.list(`/conversations/${conversationId}/users/${userId}`).valueChanges();
    return this.db
      .list(`${this.supportChatInitialPath}/conversations/${conversationId}/users/${userId}`)
      .snapshotChanges()
      .pipe(
        map((users: any) =>
          users.map(
            (user: any) => ({ [user.key]: user.payload.val() }),
            // users.map((user) => ({
            //   userId: user.key,
            //   ...(user.payload.val() as any),
            // })),
          ),
        ),
      );
  }
  // chat with us method

  // Get messages for a specific conversation
  chatWithUsGetMessages(jobId: string, conversationId: string): Observable<any[]> {
    // return this.firestore
    //   .collection('conversations')
    //   .doc(conversationId)
    //   .collection('messages', (ref) => ref.orderBy('timestamp'))
    //   .valueChanges();
    return this.db
      .list(`${this.chatWithUsInitialPath}/${jobId}/conversations/${conversationId}/messages`)
      .valueChanges();
  }

  // Send a message to a conversation
  chatWithUsSendMessage(
    jobId: string,
    conversationId: string,
    message: string,
    sender: string,
    senderID: string,
    recipientId: string,
  ) {
    // const messageData = {
    //   message,
    //   sender,
    //   timestamp: new Date(),
    //   senderID,
    // };

    // return this.firestore.collection('conversations').doc(conversationId).collection('messages').add(messageData);

    return this.db
      .list(`${this.chatWithUsInitialPath}/${jobId}/conversations/${conversationId}/messages`)
      .push({
        sender,
        message,
        timestamp: Date.now(),
        senderID,
        recipientId,
        isRead: false,
      })
      .then(() => {
        // Increment unread count for recipient
        this.db
          .object(`${this.chatWithUsInitialPath}/${jobId}/conversations/${conversationId}/users/${recipientId}`)
          .update({ unreadCount: increment(1) });
      });
  }

  chatWithUsMarkAsRead(jobId: string, conversationId: string, userId: string): Promise<void> {
    return this.db
      .object(`${this.chatWithUsInitialPath}/${jobId}/conversations/${conversationId}/users/${userId}`)
      .update({
        unreadCount: 0,
        lastRead: Date.now(),
      });
  }

  chatWithUsGetUnReadMessages(jobId: string, conversationId: string, userId: string): Observable<any[]> {
    // return this.db
    //   .list(`/conversations/${conversationId}/users`)
    //   .snapshotChanges()
    //   .pipe(
    //     map((users) =>
    //       users.map((user) => ({
    //         userId: user.key,
    //         ...(user.payload.val() as any),
    //       })),
    //     ),
    //   );
    // return this.db.list(`/conversations/${conversationId}/users/${userId}`).valueChanges();
    return this.db
      .list(`${this.chatWithUsInitialPath}/${jobId}/conversations/${conversationId}/users/${userId}`)
      .snapshotChanges()
      .pipe(
        map((users: any) =>
          users.map(
            (user: any) => ({ [user.key]: user.payload.val() }),
            // users.map((user) => ({
            //   userId: user.key,
            //   ...(user.payload.val() as any),
            // })),
          ),
        ),
      );
  }
}
