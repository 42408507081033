export enum JobStatus {
  INITIATED = 1,
  UNDER_REVIEW = 2,
  APPROVED = 3,
  IN_PROGRESS = 4,
  COMPLETED = 5,
  DECLINED = 6,
  HOLD = 7,
}

export enum MetadataTypeEnum {
  SKILLS = 1,
  WORK_CATEGORY = 2,
  PROJECT_TYPE = 3,
  SOFTWARE_SKILLS = 4,
}

export enum SubmissionStatusEnum {
  UNDER_REVIEW = 1,
  ADMIN_APPROVED = 2,
  CLIENT_APPROVED = 3,
  CLIENT_REJECTED = 4,
  DECLINED = 5,
}

export enum FinalSubmissionStatus {
  UNDER_REVIEW = 1,
  ADMIN_APPROVED = 2,
  CLIENT_APPROVED = 3,
  CLIENT_REJECTED = 4,
  REVISE = 5,
  DECLINED = 6,
}

export enum BidStatus {
  UNDER_REVIEW = 1,
  ADMIN_APPROVED = 2,
  ADMIN_REJECTED = 3,
  APPROVED = 4,
  DECLINED = 5,
  HOLD = 6,
}
export enum SupportStatusEnum {
  OPEN = 1,
  IN_PROGRESS = 2,
  CLOSED = 3,
}

export enum SupportType {
  JOB_RELATED = 'Job Related',
  ACCOUNT_RELATED = 'Account Related',
  PAYMENT_RELATED = 'Payment Related',
  OTHERS = 'Others',
}
