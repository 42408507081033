<!-- <nz-input-group [nzSuffix]="suffixIconSearch" *ngIf="!chat" class="input">
  <input type="text" nz-input placeholder="Search user id to start a new chat" />
</nz-input-group>
<ng-template #suffixIconSearch>
  <span nz-icon nzType="search"></span>
</ng-template>
<nz-list nzItemLayout="horizontal" *ngIf="!chat" class="list" [nzLoading]="loading">
  <nz-list-item *ngFor="let item of messages" style="cursor: pointer" (click)="openChat()">
    <nz-list-item-meta
      nzAvatar="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
      [nzDescription]="item.user.name"
    >
      <nz-list-item-meta-title>
        {{ item.message }}
      </nz-list-item-meta-title>
    </nz-list-item-meta>
  </nz-list-item>
  <nz-list-empty *ngIf="messages.length === 0"></nz-list-empty>
</nz-list> -->

<nz-list nzItemLayout="horizontal" nzBordered nzSize="small" class="list" *ngIf="chat" nzBordered="true" nzSplit="true">
  <nz-list-header class="header">
    <!-- <button nz-button nzShape="round"><span nz-icon nzType="arrow-left" nzTheme="outline"></span></button> -->
    <!-- <span nz-icon nzType="arrow-left" nzTheme="outline" (click)="backBtn()" class="back-btn"></span> -->
    <nz-avatar nzIcon="user" nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
    <!-- <h5 nz-typography>{{ conversationId }}</h5> -->
    <h5 nz-typography style="margin-top: 0.5rem">BD Admin</h5>
  </nz-list-header>

  <cdk-virtual-scroll-viewport [itemSize]="messages.length">
    <nz-list-item nz-col class="messages" *ngFor="let item of messages">
      <section align="center">
        <small>{{ item.timestamp | date : 'medium' }}</small>
      </section>
      <nz-row nzJustify="end" *ngIf="item.sender === 'Client' || item.sender === 'Architect'">
        <!-- <div style="margin-right: 0.2rem; margin-left: 0.2rem">
          <p nz-typography>{{ item.sender }}:</p>
        </div> -->
        <div nz-col style="padding: 0.5rem; float: right; max-width: 80%" class="border-div-sender sent">
          <ng-template #textOnly>
            {{ item.message }}
          </ng-template>
          <div
            nz-row
            type="flex"
            [nzGutter]="[
              { xs: 8, sm: 16, md: 24, lg: 32 },
              { xs: 8, sm: 16, md: 24, lg: 32 }
            ]"
            *ngIf="item.message?.files; else textOnly"
          >
            <div
              class="profile-portfolio-showcase projects"
              nz-col
              [nzSpan]="8"
              *ngFor="let files of item.message?.files; index as i"
            >
              <nz-card [nzCover]="portfolioCardTemplate" [nzBodyStyle]="{ padding: '1px' }">
                <div nz-row nzJustify="space-between">
                  <!-- <nz-tag> -->
                  <span nz-typography nz-tooltip [nzTooltipTitle]="files?.name" nzTooltipPlacement="right">
                    {{ shortenFilename(files?.name, 8, 4) }}
                  </span>

                  <!-- </nz-tag> -->
                </div>
                <ng-template #portfolioCardTemplate>
                  <div class="image-card">
                    <img
                      *ngIf="isImage(files?.short_filename); else downloadFiles"
                      nz-image
                      [nzSrc]="files?.url"
                      [alt]="files?.name"
                      nzDisablePreview="false"
                      class="image"
                    />
                    <div class="overlay">
                      <a [href]="files?.url" download
                        ><span class="icon" nz-icon nzType="download" nzTheme="outline"></span
                      ></a>
                    </div>
                  </div>
                  <!-- <span *ngIf="isPDF(files?.short_filename)" style="padding: 4px">
                    <app-pdf-viewer [data]="files"></app-pdf-viewer>
                  </span> -->

                  <ng-template #downloadFiles>
                    <a [href]="files?.url" download nz-tooltip nzTooltipTitle="Download" nzTooltipPlacement="top">
                      <img
                        [ngStyle]="{ width: '100%', height: '40%' }"
                        nz-image
                        [nzSrc]="'/assets/download.png'"
                        [alt]="files?.name"
                        nzDisablePreview="true"
                      />
                    </a>
                  </ng-template>
                </ng-template>
              </nz-card>
            </div>
          </div>
        </div>
      </nz-row>

      <nz-row nzJustify="start" *ngIf="item.sender === 'Admin'">
        <!-- <div style="margin-right: 0.2rem; margin-left: 0.2rem">
          <p nz-typography>{{ item.sender }}:</p>
        </div> -->
        <div nz-col style="padding: 0.5rem; float: left; max-width: 80%" class="border-div-admin received">
          <ng-template #textOnly>
            {{ item.message }}
          </ng-template>
          <div
            nz-row
            type="flex"
            [nzGutter]="[
              { xs: 8, sm: 16, md: 24, lg: 32 },
              { xs: 8, sm: 16, md: 24, lg: 32 }
            ]"
            *ngIf="item.message?.files; else textOnly"
          >
            <div
              class="profile-portfolio-showcase projects"
              nz-col
              [nzSpan]="8"
              *ngFor="let files of item.message?.files; index as i"
            >
              <nz-card [nzCover]="portfolioCardTemplate" [nzBodyStyle]="{ padding: '1px' }">
                <div nz-row nzJustify="space-between">
                  <!-- <nz-tag> -->
                  <span nz-typography nz-tooltip [nzTooltipTitle]="files?.name" nzTooltipPlacement="right">
                    {{ shortenFilename(files?.name, 8, 4) }}
                  </span>

                  <!-- </nz-tag> -->
                </div>
                <ng-template #portfolioCardTemplate>
                  <div class="image-card">
                    <img
                      *ngIf="isImage(files?.short_filename); else downloadFiles"
                      nz-image
                      [nzSrc]="files?.url"
                      [alt]="files?.name"
                      nzDisablePreview="false"
                      class="image"
                    />
                    <div class="overlay">
                      <a [href]="files?.url" download
                        ><span class="icon" nz-icon nzType="download" nzTheme="outline"></span
                      ></a>
                    </div>
                  </div>
                  <!-- <span *ngIf="isPDF(files?.short_filename)" style="padding: 4px">
                    <app-pdf-viewer [data]="files"></app-pdf-viewer>
                  </span> -->

                  <ng-template #downloadFiles>
                    <a [href]="files?.url" download nz-tooltip nzTooltipTitle="Download" nzTooltipPlacement="top">
                      <img
                        [ngStyle]="{ width: '100%', height: '40%' }"
                        nz-image
                        [nzSrc]="'/assets/download.png'"
                        [alt]="files?.name"
                        nzDisablePreview="true"
                      />
                    </a>
                  </ng-template>
                </ng-template>
              </nz-card>
            </div>
          </div>
        </div>
      </nz-row>

      <!-- <nz-row nzJustify="start" nzAlign="middle" *ngIf="item.sender === 'Client' || item.sender === 'Architect'">
        <div nz-col style="padding: 0.5rem; border-radius: 2rem; float: left" class="border-div received">
          {{ item.message }}
        </div>
      </nz-row>
      <nz-row nzJustify="end" nzAlign="middle" *ngIf="item.sender === 'Admin'">
        <div nz-col style="padding: 0.5rem; border-radius: 2rem; float: right" class="border-div sent">
          {{ item.message }}
        </div>
      </nz-row> -->
    </nz-list-item>
  </cdk-virtual-scroll-viewport>

  <!-- <nz-image-group *ngFor="let fileItem of fileForm.get('files')?.value">
    <img nz-image width="200px" [nzSrc]="fileItem.url" alt="" />
  </nz-image-group> -->
  <nz-list-footer class="footer">
    <!-- {{fileForm.get('files')?.value?.length}}
    <ng-container *ngFor="let fileItem of fileForm.get('files')?.value">
      <div>{{ fileItem }}</div>
    </ng-container> -->
    <!-- <nz-row>
      <nz-tag
        nzMode="closeable"
        class="editable-tag"
        nzNoAnimation
        nz-col
        nzSpan="6"
        *ngFor="let fileItem of fileForm.get('files')?.value"
      >
        <img nz-image [nzSrc]="fileItem.url" alt="uploaded-image" />
      </nz-tag>
      <br />
    </nz-row> -->
    <br />
    <div>
      <!-- <app-file-upload [uploadType]="'chat'" [form]="fileForm" [fileFormControlName]="'files'"></app-file-upload> -->
      <!-- <div> -->
      <button class="attached" nz-button nzShape="circle" nzType="primary" (click)="openFileUpload()">
        <span nz-icon nzType="paper-clip" style="color: white"></span>
      </button>
      <textarea
        [(ngModel)]="newMessage"
        nz-input
        placeholder="Enter an message"
        rows="1"
        (keyup.enter)="sendMessage()"
      ></textarea>
      <button class="send" nz-button nzShape="circle" nzType="primary" type="submit">
        <span nz-icon nzType="send" style="color: white" (click)="sendMessage()"></span>
      </button>
    </div>
    <!-- </div> -->
  </nz-list-footer>
</nz-list>
<!-- </cdk-virtual-scroll-viewport> -->
<!-- File upload -->
<nz-modal
  [(nzVisible)]="showFileUpload"
  [nzContent]="fileModalContent"
  [nzFooter]="null"
  (nzOnCancel)="closeFileUpload()"
  nzClassName="coa-modal"
  [nzStyle]="{ top: '', borderRadius: '0px', height: '60vh' }"
  [nzBodyStyle]="{ borderRadius: '8px', padding: '0px' }"
  nzClosable="false"
>
  <!-- [nzBodyStyle]="{ height: '200px' }" -->
  <!-- [nzTitle]="modalTitle" -->
  <ng-template #fileModalTitle>
    <!-- Custom Modal Title -->
  </ng-template>

  <ng-template #fileModalContent>
    <!-- {{ fileForm.get('files')?.value?.length }}
  <ng-container *ngFor="let fileItem of fileForm.get('files')?.value">
    <div>{{ fileItem }}</div>
  </ng-container>
  <nz-row>
    <nz-tag
      nzMode="closeable"
      class="editable-tag"
      nzNoAnimation
      nz-col
      nzSpan="6"
      *ngFor="let fileItem of fileForm.get('files')?.value"
    >
      <img nz-image [nzSrc]="fileItem.url" alt="uploaded-image" />
    </nz-tag>
    <br />
  </nz-row> -->
    <div nz-row>
      <div nz-col nzSpan="24" [align]="'center'">
        <app-file-upload [uploadType]="'chat'" [form]="fileForm" [fileFormControlName]="'files'"></app-file-upload>
        <button
          class="send-attached"
          nz-button
          nzShape="circle"
          nzType="primary"
          type="button"
          (click)="sendMessageWithAttachement()"
        >
          <span nz-icon nzType="send" style="color: white"></span>
        </button>
      </div>
    </div>
  </ng-template>
</nz-modal>
